<template>
  <div class="transaction">
    <header-page>
      Ticket Support
    </header-page>
    <div class="transaction__filter--container py-1">
      <div class="row mx-0 justify-content-between w-100 flex-lg-row flex-column-reverse">
        <b-col
          cols="12"
          md="5"
          lg="5"
        >
          <b-button-group>
            <b-button
              v-for="(item, index) in listFilter"
              :id="`button__filter__${index}`"
              :key="`${item}-${index}`"
              class="filter--button"
              :class="{ 'active' : item.name == filters }"
              @click="status = item.value, filters = item.name"
            >
              {{ item.name }}
            </b-button>
          </b-button-group>
        </b-col>
        <b-col
          cols="12"
          md="7"
          lg="7"
          class="d-flex search__export justify-content-end"
        >
          <div class="search__input">
            <b-input-group>
              <b-form-input
                v-model="filter.search"
                placeholder="Cari subjek atau pesan..."
              />
              <b-input-group-append is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-append>
            </b-input-group>
          </div>

          <!-- <b-button variant="outline-secondary py-1 bg-white mx-1">
              <b-img :src="require('../../assets/images/icons/export.svg')" />
              <span>Export</span>
            </b-button> -->
          <b-button
            v-b-modal.form-modal
            variant="outline-secondary py-0 bg-secondary text-white"
          >
            <span>+ Buat Ticket</span>
          </b-button>
        </b-col>
      </div>
    </div>

    <!-- Add/Edit Modal -->
    <b-modal
      id="form-modal"
      size="lg"
      title="Buat Tiket"
    >

      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="">Subjek:</label>
            <input
              v-model="formPayload.subject"
              type="text"
              class="form-control"
              placeholder="Masukan judul tiket/masalah Anda disini..."
            >
          </div>
        </div>
        <div class="col">
          <b-form-group>
            <label for="item_uuid">Kategori</label>
            <b-form-select
              v-model="formPayload.category"
              :options="options"
            />
          </b-form-group>
        </div>
        <!-- <div class="col">
          <div class="form-group">
            <label for="">Prioritas:</label>
            <b-form-select
              v-model="formPayload.priority"
              :options="['Low', 'Normal', 'High']"
            />
          </div>
        </div> -->
      </div>

      <div class="form-group">
        <label for="">Pesan:</label>
        <textarea
          v-model="formPayload.message"
          type="text"
          class="form-control"
          rows="5"
          placeholder="Jelaskan detail masalah Anda disini..."
        />
      </div>

      <div class="form-group">
        <label for="">Attachment (Opsional):</label>
        <DropzoneFile @files="getFiles" :clearAttachment="clearAttachment" />
        <!-- <input
          id="file"
          ref="file"
          type="file"
          @change="changeAttachment()"
        > -->

        <div class="alert alert-info p-1 mt-1">
          <strong>Catatan</strong>
          <ul>
            <li>File yang didukung adalah: pdf,doc,docx,xls,xlsx,jpg,jpeg,png</li>
            <li>Maksimal ukuran file adalah 2MB</li>
          </ul>
        </div>
      </div>

      <template #modal-footer="{}">
        <div v-if="isLoading">
          <br>
          <b-spinner
            class="mb-2"
            variant="primary"
          /><br>
        </div>
        <b-button
          variant="success"
          :disabled="isLoading"
          @click="submitTicket()"
        >
          Buat Tiket
        </b-button>
      </template>
    </b-modal>
    <!-- Add/Edit Modal -->

    <!-- Add/Edit Modal -->
    <b-modal
      id="detail-modal"
      size="lg"
      :title="`Detail Tiket ${detail.id}`"
    >

      <table
        v-if="detail.uuid != undefined"
        class="table"
      >
        <tr>
          <td width="30%">
            Subjek
          </td>
          <td width="5%">
            :
          </td>
          <td width="65%">{{ detail.subject }}</td>
        </tr>
        <tr>
          <td>
            Kategori
          </td>
          <td>:</td>
          <td style="text-transform: capitalize;">
            {{ detail.category }}
          </td>
        </tr>
        <tr>
          <td>
            Status
          </td>
          <td>:</td>
          <td>
            <b-badge :variant="statusVariant(detail.status)">
              {{ detail.status.charAt(0).toUpperCase() + detail.status.slice(1) }}
            </b-badge>
          </td>
        </tr>
        <tr>
          <td>
            Dibuat
          </td>
          <td>:</td>
          <td>{{ detail.created_at }}</td>
        </tr>
        <tr>
          <td>
            Terakhir Dibalas
          </td>
          <td>:</td>
          <td>{{ detail.last_replied_at }}</td>
        </tr>
        <tr>
          <td>
            Attachment
          </td>
          <td>:</td>
          <td>
            <a
              v-if="detail.attachment_url"
              :href="detail.attachment_url"
              target="_blank"
            >
              Lihat Attachment
            </a>
            <span v-else>
              Tidak ada attachment
            </span>
          </td>
        </tr>
      </table>

      <br>
      <p>
        {{ detail.message }}
      </p>

      <hr>

    </b-modal>
    <!-- Add/Edit Modal -->

    <vue-good-table
      max-height="80vh"
      :columns="columns"
      :rows="items"
      :fixed-header="false"
      :search-options="{
        enabled: false, }"
      :sort-options="{
        enabled: false
      }"
      :pagination-options="{
        enabled: false,
      }"
    >
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.field =='action'">
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle"
          />
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >

        <!-- Column: Status -->
        <span
          v-if="props.column.field === 'status'"
          class="text-capitalize"
        >
          <b-badge :variant="statusVariant(props.row.status)">
            {{ props.row.status.charAt(0).toUpperCase() + props.row.status.slice(1) }}
          </b-badge>
        </span>

        <!-- Column: Category -->
        <span
          v-else-if="props.column.field === 'category'"
          class="text-capitalize"
        >
          {{ props.row.category || '-' }}
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span v-if="props.row.status !== 'canceled'">
            <b-dropdown
              id="dropdown-dropleft"
              class="d-flex"
              right
            >
              <template
                #button-content
                class="btn-white text-center"
              >
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle"
                />
              </template>
              <!-- <b-dropdown-item @click="$router.push({ name: 'invoice.index', params: { id: props.row.uuid }, query: { type: 'show_transaction' } })">
                  Lihat Transaksi
                </b-dropdown-item> -->
              <!-- <b-dropdown-item v-if="checkPermission('edit transaksi')" @click="$router.push({ name: 'transaction.show', params: { id: props.row.uuid } })">
                  Edit Transaksi
                </b-dropdown-item> -->
              <b-dropdown-item @click="detailTicket(props.row.uuid)">
                Lihat Detail
              </b-dropdown-item>
              <!-- <b-dropdown-item
                @click="closeTicket(props.row.uuid)"
              >
                <b-spinner v-if="isLoading" />
                Close Ticket
              </b-dropdown-item> -->
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
    <!-- Pagination -->
    <div
      v-if="rows"
      class="d-flex justify-content-between align-items-center flex-wrap bg-white border"
      style="padding: 0.8rem;"
    >
      <div class="d-flex align-items-center">
        <b-form-select
          v-model="filter.per_page"
          :options="['10','15','20']"
          class="mx-1"
        />
        <span class="text-nowrap">
          Tampilkan {{ (rows.total > 0) ? (filter.per_page * (rows.current_page - 1)) + 1 : 0 }} ke
          {{ (rows.total > 0) ? Math.min(filter.per_page * rows.current_page, rows.total) : 0 }} dari {{ rows.total }} transaksi.
        </span>
      </div>
      <div>
        <b-row class="mx-0 align-items-center">
          <b-col>
            <pagination
              class="mb-0"
              :data="rows"
              :limit="4"
              align="right"
              @pagination-change-page="getData"
            />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BRow, BCol, BFormInput, BButton, BButtonGroup, BDropdown, BDropdownItem, BBadge, BImg, BInputGroup, BInputGroupAppend, BFormSelect, BSpinner, BFormGroup
} from 'bootstrap-vue'
import { checkPermission, errorNotification } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'
import FilterButton from '@/components/BaseFilter.vue'
import HeaderPage from '@/components/HeaderPage.vue'

import vSelect from 'vue-select'

import DropzoneFile from '@/components/Dropzone/DropzoneFile.vue'

export default {
  title() {
    return 'Ticket Support'
  },
  components: {
    BRow,
    VueGoodTable,
    BButton,
    BFormInput,
    HeaderPage,
    BButtonGroup,
    BDropdown,
    BDropdownItem,
    BBadge,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect,
    BCol,
    BSpinner,
    BFormGroup,
    vSelect,
    DropzoneFile
  },
  setup() {
    return {
      checkPermission, errorNotification,
    }
  },
  data() {
    return {
      clearAttachment: false,
      options: [
        { value: '', text: 'Pilih kategori' },
        { value: 'Fungsi Sistem', text: 'Fungsi Sistem' },
        { value: 'Langganan', text: 'Langganan' },
        { value: 'Lainnya', text: 'Lainnya' },
      ],
      detail: {},
      formPayload: {
        subject: '',
        category: '',
        message: '',
        attachment: '',
      },
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: 'No Ticket',
          field: 'id',
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Subjek',
          field: 'subject',
        },
        {
          label: 'Tanggal Dibuat',
          field: 'created_at',
        },
        {
          label: 'Terakhir Dibalas',
          field: 'last_replied_at',
        },
        {
          label: 'Kategori',
          field: 'category',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      rows: {},
      searchTerm: '',
      status: [{
        1: 'Current',
        2: 'Professional',
        3: 'Rejected',
        4: 'Resigned',
        5: 'Applied',
      },
      {
        1: 'light-primary',
        2: 'light-success',
        3: 'light-danger',
        4: 'light-warning',
        5: 'light-info',
      }],
      listFilter: [
        {
          name: 'Semua',
          value: '',
        },
        {
          name: 'Open',
          value: 'open',
        },
        {
          name: 'Closed',
          value: 'closed',
        },
        // {
        //   name: 'Retur',
        //   value: 'retur',
        // },
        // 'Semua', 'Lunas', 'Belum Lunas', 'Retur'
      ],
      filters: 'Semua',
      filter: {
        status: '',
        search: '',
        sort_type: 'asc',
        per_page: 10,
      },
      isLoading: false,
      returData: null,
      status: '',
      page: 1,
      items: [],
      optionItems: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        open: 'light-success',
        close: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  watch: {
    status: {
      handler: _.debounce(function (val) {
        this.filter.status = val
        this.getData()
      }, 300),
      deep: true,
    },
    'filter.search': {
      handler: _.debounce(function (val) {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getFiles(files) {
      this.formPayload.attachment = files
    },
    changeAttachment() {
      // eslint-disable-next-line prefer-destructuring
      this.formPayload.attachment = this.$refs.file.files[0]
    },
    getFilterBase(value) {
      this.$router.replace({ query: '' })
      this.filter = {
        ...value,
      }
    },
    closeTicket(id) {
      this.$swal({
        title: 'Konfirmasi',
        text: 'Apa Anda yakin ingin close ticket ini?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.$http.post(`ticket/${id}/close`).then(() => {
            this.isLoading = false
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: 'Tiket berhasil ditutup',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.getData()
          }).catch(err => {
            this.isLoading = false
            // eslint-disable-next-line no-console
            console.log(err)
          })
        }
      })
    },
    getData(page = this.$route.query.page || 1) {
      const queryParams = this.filter
      if (this.$route.query.date) {
        queryParams.filter_date_type = 'date'
        queryParams.payment_method = 'cash'
        queryParams.filter_date_value = this.$route.query.date
      }
      queryParams.page = page
      this.page = page
      this.$http.get('ticket/', {
        uuid: '',
        params: queryParams,
      }).then(result => {
        this.rows = result.data.data
        this.items = result.data.data.data
      }).catch(err => {
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    clearPayload() {
      this.formPayload = {
        subject: '',
        category: '',
        message: '',
        attachment: '',
      }
    },
    submitTicket() {
      this.isLoading = true
      
      const formData = this.preparePayload()

      this.$http.post('ticket/', formData).then(() => {
        this.isLoading = false
        this.$bvModal.hide('form-modal')
        this.clearPayload()
        this.clearAttachment = true
        this.getData()
      }).catch(err => {
        this.isLoading = false
        if (err.response.data.meta.messages) {
          errorNotification(this, 'Oops!', err.response.data.meta.messages)
        }
        // eslint-disable-next-line no-console
        console.log(err)
      })
      this.clearAttachment = false
    },
    detailTicket(uuid) {
      this.$router.push({ name: 'ticket.show', params: { id: uuid }, query: { page: this.page || 1 } })
      // this.$http.get(`/ticket/${uuid}`)
      //   .then(response => {
      //     this.detail = response.data.data
      //   })

      // this.$bvModal.show('detail-modal')
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null) {
          form.append(key, this.formPayload[key])
        }
        if (Array.isArray(this.formPayload[key])) {
          this.formPayload[key].forEach((attachment) => {
            form.append("attachments[]", attachment);
          });
        }
      }
      return form
    },
  },
}
</script>

  <style lang="scss">
  @import '@/assets/scss/variables/_variables.scss';
  @import '@core/scss/vue/libs/vue-good-table.scss';
  @import '@core/scss/vue/libs/vue-select.scss';

  .transaction {
    position: relative;

    .pagination__height {
      height: 80px;
    }

    @media screen and (min-width: 550px) {
      .pagination__height {
        height: 100%;
      }
    }

    .vgt-responsive {
      height: calc(100vh - 223px) !important;
      background-color: #fff;
    }

    .vgt-table.bordered {
      thead {
        th:last-child {
          text-align: center;
        }
      }

      td {
        border: none;
        border-bottom: 1px solid #ebe9f1;
        vertical-align: middle;
      }

      th {
        border: none;
        border-bottom: 1px solid #ebe9f1;
        color: #6B6C7E;
        font-weight: 700;
      }

      .bg-danger {

        &:hover,
        &:focus {
          background-color: #ffeef1 !important;
          box-shadow: none;
        }
      }

    }

    .page-item {
      .page-link {
        font-weight: 600;
        color: $secondary-2 !important;
        background-color: transparent;
      }

      &.active {
        .page-link {
          border-radius: 0;
          background-color: $light--1;
          font-weight: 600;
          color: $secondary-2 !important;
        }
      }
    }

    .page-item.prev-item~.page-item:nth-child(2) .page-link {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }

    .page-item.prev-item~.page-item:nth-last-child(2) .page-link {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }

    .page-item.next-item .page-link:active,
    .page-item.next-item .page-link:hover {
      background-color: transparent !important;
    }

    .pagination__container {
      width: 100%;
      height: 80px;
      position: fixed;
      bottom: 0;
      background: #FFFFFF;
      box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.06);
      border: 1px solid #E4E5EC;

      .dropdown {

        button {
          font-size: 14px;
          color: $dark;
          font-weight: 600;

          &:hover {
            background-color: transparent;
          }
        }
      }

      small {
        color: $secondary-2;
        font-size: 14px;
        font-weight: 400;
      }
    }

    #dropdown-dropleft {
      .dropdown-toggle {
        border-color: transparent !important;
        background-color: transparent !important;
        color: $dark !important;

        &:hover {
          border-color: transparent !important;
          background-color: transparent !important;
          box-shadow: none !important;
        }
      }
    }

    .transaction__filter--container {
      display: flex;
      justify-content: space-between;

      .search__export {
        .search__input {
          margin-right: 8px;

          .input-group {
            width: 23.451vw;
            height: 48px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

            input {
              height: 48px;
              border: none;
              border-top-left-radius: 8px;
              border-bottom-left-radius: 8px;
            }

            .input-group-append {
              .input-group-text {
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                border: none;
              }
            }
          }
        }

        #search {
          width: 278px;
          height: 48px;
          border: none;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);

        }

        .btn.bg-white {
          border: none !important;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          border-radius: 8px;

          img {
            margin-right: 8px;
          }

          span {
            color: $dark;
            font-size: 14px;
            font-weight: 800;
          }
        }
      }

      .filter--button {
        border: none !important;
        color: $light--2 !important;
        background-color: transparent !important;
        border-color: transparent !important;

        &.active {
          color: $secondary !important;
          position: relative;

          &::after {
            content: '';
            position: absolute;
            bottom: -48%;
            left: 0;
            width: 50%;
            margin-left: 25%;
            border-radius: 4px 4px 0px 0px;
            border: 2px solid $secondary;
          }
        }

        &:hover {
          box-shadow: none !important;
        }
      }
    }
  }
</style>
