<template>
    <div class="main">
        <div class="dropzone-container" @dragover="dragover" @dragleave="dragleave" @drop="drop">
            <input type="file" multiple name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file"
                accept=".pdf, .doc, .docx, .xls, .xlsx, .jpg, .jpeg, .png" />
            <label for="fileInput" class="file-label">
                <div v-if="isDragging">Release to drop files here.</div>
                <div v-else>Drop files here or <u>click here</u> to upload.</div>
            </label>

            <div class="preview-container mt-2" v-if="files.length">
                <div class="preview-card" v-for="(file, index) in files" :key="file.name">

                    <div>
                        <img class="preview-img" :src="generateURL(file)" />
                        <p class="text-dark font-weight-bold">{{ file.name }}</p>
                        <!-- <span class="text-dark font-weight-bold">{{ Math.round(file.size / 1000) + "kb" }}</span> -->
                    </div>

                    <div style="position: absolute; right: 0; top: 0">
                        <b-button variant="danger" :id="`remove-file-${index}`" @click="remove(files.indexOf(file))"
                            title="Remove file">
                            <feather-icon icon="Trash2Icon" />
                        </b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { BButton } from 'bootstrap-vue'

export default {
    components: {
        BButton,
    },
    data() {
        return {
            isDragging: false,
            files: [],
        }
    },
    props: ['clearAttachment'],
    watch: {
        files: {
            handler(files) {
                this.$emit('files', files)
            },
            deep: true
        },
        clearAttachment(value) {
            if (value) {
                this.files = []
            }
        }
    },
    methods: {
        onChange() {
            const self = this;
            const incomingFiles = Array.from(this.$refs.file.files);

            const fileExist = self.files.some(existingFile =>
                incomingFiles.some(file =>
                    file.name === existingFile.name && file.size === existingFile.size
                )
            );

            if (fileExist) {
                self.showMessage = true;
                alert("File upload terbaru berisi file yang sudah ada");
            } else {
                self.files.push(...incomingFiles);
            }
        },
        dragover(e) {
            e.preventDefault();
            this.isDragging = true;
        },
        dragleave() {
            this.isDragging = false;
        },
        drop(e) {
            e.preventDefault();
            this.$refs.file.files = e.dataTransfer.files;
            this.onChange();
            this.isDragging = false;
        },
        remove(i) {
            this.files.splice(i, 1);
        },
        generateURL(file) {
            let fileSrc = URL.createObjectURL(file);
            setTimeout(() => {
                URL.revokeObjectURL(fileSrc);
            }, 1000);
            return fileSrc;
        },
        uploadFiles() {
            const files = this.files;
            const formData = new FormData();

            files.forEach((file) => {
                formData.append("selectedFiles", file);
            });

            // axios({
            //   method: "POST",
            //   url: "http://path/to/api/upload-files",
            //   data: formData,
            //   headers: {
            //     "Content-Type": "multipart/form-data",
            //   },
            // });
        },
    }
}
</script>

<style lang="scss" scoped>
.main {
    width: 100%;
    text-align: center;
}

.dropzone-container {
    padding: 2rem;
    background-color: #fff;
    border: 1px solid #d8d6de;

    border-radius: 6px;
}

.hidden-input {
    opacity: 0;
    overflow: hidden;
    position: absolute;
    width: 1px;
    height: 1px;
}

.file-label {
    font-size: 1em;
    display: block;
    cursor: pointer;
}

.preview-container {
    display: flex;
    flex-wrap: wrap;
    gap: 6px;

    margin-top: 2rem;
}

.preview-card {
    position: relative;

    display: flex;
    border: 1px solid #a2a2a2;
    padding: 6px;
    margin-left: 6px;
    border-radius: 6px;

    button {
        padding: 7px;
    }

    p {
        width: 150px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        margin: 0.5rem 0;
    }
}

.preview-img {
    object-fit: cover;
    width: 150px;
    height: 150px;
    border-radius: 6px;
}
</style>